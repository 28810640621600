.swiper {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: relative; /* Ensure child elements can be absolutely positioned */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-navigation {
display: flex;


}

.custom-prev,
.custom-next {
  background-color: transparent;
  pointer-events: auto; /* Allow buttons to be clickable */
  border: none;
  cursor: pointer;
}

.custom-prev {
  position: absolute;
  left: 10px; /* Adjust to move the button closer/farther from the edge */
}

.custom-next {
  position: absolute;
  right: 10px; /* Adjust to move the button closer/farther from the edge */
}

.image {
width: 200px;
}
.imagem{
  width: 60px;
}