

header{
  position: absolute;
  z-index: 10;

  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 24px;
  box-sizing: border-box;
  background: #ffbf47;
  color: white !important;
  font-family: "Poppins", sans-serif;
}

header .menu-btn{
  font-size: 20px;
  background-color: #1577ff;
  border: 3px solid #000000;
  z-index: 10;

  cursor: pointer;
  border-radius: 25px;
  padding: 20px;
}

.menu-container{
  position: absolute;
overflow-x: hidden !important;
  width: 100%;
  height: 100vh;
  background-color:  #ffbf47;
  color: white !important;
  z-index: 9;text-shadow: #000 0.2px 0 0,
  #000 3.87565px .989616px 0,
  #000 3.51033px 1.9177px 0,
  #000 2.92676px 2.72656px 0,
  #000 2.16121px 3.36588px 0,
  #000 1.26129px 3.79594px 0,
  #000 .282949px 3.98998px 0,
  #000 -.712984px 3.93594px 0,
  #000 -1.66459px 3.63719px 0,
  #000 -2.51269px 3.11229px 0,
  #000 -3.20457px 2.39389px 0,
  #000 -3.69721px 1.52664px 0,
  #000 -3.95997px .56448px 0,
  #000 -3.97652px -.432781px 0,
  #000 -3.74583px -1.40313px 0,
  #000 -3.28224px -2.28625px 0,
  #000 -2.61457px -3.02721px 0,
  #000 -1.78435px -3.57996px 0,
  #000 -.843183px -3.91012px 0,
  #000 .150409px -3.99717px 0,
  #000 1.13465px -3.8357px 0,
  #000 2.04834px -3.43574px 0,
  #000 2.83468px -2.82216px 0,
  #000 3.44477px -2.03312px 0,
  #000 3.84068px -1.11766px 0,
  #000 3.9978px -.132717px 0,
  #000 0 1px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 100%;
  text-decoration: none !important;
}

.menu-container nav{
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 36px;
  position: relative;
  top: -60px;
}

.menu-container nav > a{
  font-size: 2rem;
  color: #ffffff;
  text-decoration: none;
  margin: 10px 0px;
  font-weight: 900;
  font-weight: lighter;

}

.menu-container nav > a:hover{
  text-decoration: underline;
  color: #ffe600;
}
