@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&display=swap');
html{
  font-family: 'YourCustomFont' !important;
  scroll-behavior: smooth !important;
  
}

:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}
*{

}

body {
  font-family: 'YourCustomFont' !important;
  overflow-x: hidden !important;
}
.ready{
  font-family: 'YourCustomFont' !important;
  text-align: center;
  font-size: 20px;

}

@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/SuperMario256.ttf') format('truetype');
}

@font-face {
  font-family: 'YourCustomFonts';
  src: url('./Fonts/Squad-ExtraBold.ttf') format('truetype');
}




/* Custom Navbar */
.custom-navbar {
  background-color:#ffbf47;
  border-bottom: 5px solid #000;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;


  height: 70px;

  font-size: 20px;
}

.text-whites{
  color: rgb(255, 255, 255) !important;

  color: #ffbf47 !important;
 text-shadow: #000 0.2px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 1px 0;
}

/* Glassy effect on scroll */
.custom-navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
  backdrop-filter: blur(10px); /* Glassy effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

/* Custom Nav positioning */
.custom-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 1rem; /* Space from the right edge */
}

.custom-nav .nav-link {
  color: rgb(247, 247, 247);
  margin-left: 1rem; /* Space between links */
  transition: color 0.3s ease;
}

.custom-nav .nav-link:hover {
  color: #f0ad4e; /* Highlight on hover */
}


.shimanofas {
  display: flex; /* Ensure flex properties work */
  text-align: center;
 
  justify-content: center;
  align-items: center;
  color: #fccf00; /* Text color */
  font-size: 50px !important;
  font-weight: 900;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
  letter-spacing: 3px;
  font-size: 80px;
}




@media screen and (min-width: 1000px){


  .shimanofas {
    display: flex; /* Ensure flex properties work */
    text-align: center;
    justify-content: center;
    align-items: center;
    color:#fccf00 !important; /* Text color */
    font-size: 100px !important;
    font-weight: 900;
    text-shadow: #000 4px 0 0,
                 #000 3.87565px .989616px 0,
                 #000 3.51033px 1.9177px 0,
                 #000 2.92676px 2.72656px 0,
                 #000 2.16121px 3.36588px 0,
                 #000 1.26129px 3.79594px 0,
                 #000 .282949px 3.98998px 0,
                 #000 -.712984px 3.93594px 0,
                 #000 -1.66459px 3.63719px 0,
                 #000 -2.51269px 3.11229px 0,
                 #000 -3.20457px 2.39389px 0,
                 #000 -3.69721px 1.52664px 0,
                 #000 -3.95997px .56448px 0,
                 #000 -3.97652px -.432781px 0,
                 #000 -3.74583px -1.40313px 0,
                 #000 -3.28224px -2.28625px 0,
                 #000 -2.61457px -3.02721px 0,
                 #000 -1.78435px -3.57996px 0,
                 #000 -.843183px -3.91012px 0,
                 #000 .150409px -3.99717px 0,
                 #000 1.13465px -3.8357px 0,
                 #000 2.04834px -3.43574px 0,
                 #000 2.83468px -2.82216px 0,
                 #000 3.44477px -2.03312px 0,
                 #000 3.84068px -1.11766px 0,
                 #000 3.9978px -.132717px 0,
                 #000 0 4px 0;
    letter-spacing: 3px;
    font-size: 80px;
  }
  
  
  
  
  
}



.memec{
  font-family: 'YourCustomFonts';
  font-size: 30px;
  font-weight: 500;
  color: #fef105;

  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
}


.memecs{
  font-family: 'YourCustomFonts';
  font-size: 30px;
  font-weight: 500;
  color: #ff65b0;

  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
}



.ques{
  font-family: 'YourCustomFontNumber' ;
}
@font-face {

 
}
.fontz{
  font-family: 'YourCustomFont' !important;
  text-shadow: #000 0.5px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 1px 0;
  
}
.shima_head {
  text-align: center;
  font-size: 100px;
  font-weight: bold;
color: #000;
  letter-spacing:0px; /* Add this to increase space between letters */
}
@media screen and (min-width: 1000px){
  .shima_head {
    text-align: center;
    font-size: 150px;
    font-weight: bold;
  color: #000;
    letter-spacing:10px; /* Add this to increase space between letters */
  }
  
}



.shima_headl {
  
  font-size: 65px;
  font-weight: bold;
  background: linear-gradient(45deg, #ffffff, #ffffff, rgb(241, 241, 241), #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2),
               4px 4px 12px rgba(0, 0, 0, 0.15);

}



.centerall{
  text-align: center;
  justify-content: center;
  align-items: center;

}

/* Additional font-face rules if you have more fonts */

/* Define global font styles */
.matrix-text {
  color: rgb(255, 255, 255);
  font-family: 'YourCustomFont' !important;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


.image_anos{
  width: 60%;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anos{
    width: 50%;
  }

      }



.anos_text{
  font-size: 2rem !important;
  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}





.anon_p{

  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .anon_p{
 
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}

.image_anos_uni{
  width: 60%;
}

@media screen and (min-width: 1000px){
  .image_anos_uni{
    width: 50%;
  }
}

.image_anos_dex{
  width: 55%;
}

@media screen and (min-width: 1000px){
  .image_anos_dex{
    width: 45%;
  }
}

.blue-text {
  font-size: 1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .blue-text {
    color: #ffffff;
    font-size: 2.5rem !important;
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.red-text {
  font-size:1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .red-text {

    font-size: 2.5rem !important;
    color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.token_text{
  font-size: 1.4rem !important;
  color: rgb(255, 255, 255);

  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .token_text{
    font-size: 1.4rem !important;
    color: rgb(255, 255, 255);

    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}




.card {
    position: relative;
    width: auto;
    height: 100%;
    background-color: #000;
    border-radius: 8px;
}






























@import"https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap";.inputCls[type=number] {
  -moz-appearance: textfield
}


.footer {
 
  background-color: #25252500 !important;

}

.card {
  position: relative;
  width: auto;
  height: 100%;
  background-color: #000000c1 !important;
  border-radius: 8px
}

.card-content {
  padding: 20px
}

.card-title {
  margin: 0;
  font-size: 24px
}

.card-description {
  margin: 10px 0
}

.card-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #ff0400;
  color: #fff;
  text-decoration: none;
  border-radius: 4px
}

.boarding {
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border: 4px solid #bb00ff;
  pointer-events: none;
  border-radius: 12px;
  opacity: 0;
  animation: boardingAnimation 2s linear infinite
}

@keyframes boardingAnimation {
  0% {
      opacity: 0
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat
}

*,:before,:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,:after {
  --tw-content: ""
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal
}



.tokcolo{
  color:  #fff !important;
  font-size: 3rem !important;
}




/* Style the audio container */
audio {
  background-color:transparent !important;
  color: white;
  width: 300px;
}

/* Style the audio controls */
audio::-webkit-media-controls {
  background-color: #a306067b;
  border-radius: 40%;
}

audio::-webkit-media-controls-play-button {
  background-color: #7c040481;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #ff222263;
}

audio::-webkit-media-controls-panel {
  background-color: #860000d8;
  color: white;
}

/* Style the audio controls for Firefox */
audio::-moz-media-controls {
  background-color: #444;
  color: white;
}

/* Style the audio controls for Edge/IE */
audio::-ms-media-controls {
  background-color: #444;
  color: white;
}





.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
 width: 100%;
 position: relative;
 top: 50px;
 box-shadow: inset -8px -8px 0 #252525;
  pointer-events: auto;
  background-color: rgb(46, 46, 46) !important;
  background-clip: padding-box;
  border: 2px solid rgb(82, 82, 82);
  border-radius: 0.5rem;
  outline: 0;
  top: 150px;
}







































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);

  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}

.popins{
  font-family: 'YourCustomFontNumber' !important;
}
.popinsf{
  font-family: 'YourCustomFontNumber' !important;
  font-size: 20px !important;
}



.doyou {
  font-family: "Poppins", system-ui !important;
  text-decoration: underline;
  text-underline-offset: 10px !important; /* Adjust the value as needed */
}

.typing-demo {
  font-family: "Poppins", system-ui !important;
  font-size: 14px !important;

}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: calc(100% + 2ch); /* Adjust width based on text */
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.glowing-checkbox {
  top: 5px;
  right: 5px;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #555;
  border-radius: 3px;
  position: relative;

  transition: all 0.3s ease;
  /* Initial scale for animation */
  transform: scale(1);
}

.glowing-checkbox:checked {
  background-color: #000000;

  border-color: #000000;
  /* Glowing box shadow when checked */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  /* Smoothly scale up the checkbox when checked */
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
}

.checkbox-text {
  font-size: 12px !important;
  font-family: "Poppins", system-ui !important;
  color: #000000;
  margin-left: 10px;
  /* Add fade-in effect to the text */


  transition: all 0.4s ease;
}

.glowing-checkbox:checked + .checkbox-text {
  /* Fade in and slide the text when the checkbox is checked */
  opacity: 1;
  transform: translateX(0);
}

/* Animation when hovering over checkbox */
.glowing-checkbox:hover {
  /* Subtle glow effect when hovering */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  /* Slight scale effect on hover */
  transform: scale(1.1);
}





.styled-button {
  font-size: 35px !important;
  color: black;
  position: relative;
  left: 30px;
  top: 4px;
  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 110px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;

}

.styled-button:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}






.styled-buttonh {
  font-size: 35px !important;
  color: black;
  position: relative;
  left: 100px;
  top: 4px;
  background-color: white;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 180px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;


}

.styled-buttonh:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}









.button-container {
  display: flex;
  justify-content: end;
  text-align: end;



}






































.x-button {
  background-color: #000000; /* Button background color (brownish shade) */
  border: none;
  border-radius: 12px; /* Rounded corners */
position: relative;
top: 16px;
right: 35px;

height: 35px;
width: 38px;
  align-items: center;
  justify-content: center;
}

.x-icon {
  color: white; /* Icon color */
  font-size: 20px !important; /* Adjust the size of the icon */
}

.x-button:hover {
  background-color: #000000; /* Slightly darker color on hover */
}


.tw{
  position: relative;
  left:  0px !important;
}

.ds{
  position: relative;
  left:  25px !important;
}


.BlurBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-image: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px); /* Apply blur to only the background */
  z-index: 0; /* Keeps it behind the content */
}

.GlassOverlay {
  position: relative;
  width: 80%; /* Adjust to your desired size */
  padding: 20px;

  color: #000; /* Text color */
  z-index: 1; /* Keeps content above the blurred background */
  margin: 50px auto; /* Centers the overlay */
  text-align: center; /* Centers text inside */
}






























.about_container {
  display: flex;
  justify-content: center;
  align-items: center;

 
  margin: 0;
}

.about-button {
  padding: 20px 55px;
  border: 3px solid #000000; /* Dark purple border */
  background-color: #f3f3f3; /* White background */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Soft shadow for the button */
  border-radius: 5px; /* Slight rounding of the corners */
}

.about-button .text {
  
  font-size: 26px !important;
  text-shadow: #000 4px 0 0,
  #000 3.87565px .989616px 0,
  #000 3.51033px 1.9177px 0,
  #000 2.92676px 2.72656px 0,
  #000 2.16121px 3.36588px 0,
  #000 1.26129px 3.79594px 0,
  #000 .282949px 3.98998px 0,
  #000 -.712984px 3.93594px 0,
  #000 -1.66459px 3.63719px 0,
  #000 -2.51269px 3.11229px 0,
  #000 -3.20457px 2.39389px 0,
  #000 -3.69721px 1.52664px 0,
  #000 -3.95997px .56448px 0,
  #000 -3.97652px -.432781px 0,
  #000 -3.74583px -1.40313px 0,
  #000 -3.28224px -2.28625px 0,
  #000 -2.61457px -3.02721px 0,
  #000 -1.78435px -3.57996px 0,
  #000 -.843183px -3.91012px 0,
  #000 .150409px -3.99717px 0,
  #000 1.13465px -3.8357px 0,
  #000 2.04834px -3.43574px 0,
  #000 2.83468px -2.82216px 0,
  #000 3.44477px -2.03312px 0,
  #000 3.84068px -1.11766px 0,
  #000 3.9978px -.132717px 0,
  #000 0 4px 0;
  color: #000; /* Black text color */
}

.about-button .diamond {
  font-family: 'Arial', sans-serif;
  font-size: 22px;
  font-weight: normal;
  display: inline-block;
  transform: scale(1.2); /* Enlarge the diamond a little */
}































.paf{
  font-family: 'YourCustomFonts' !important;
}




.containera {
text-align: start;
font-family: 'YourCustomFonts' !important;
  margin: 50px auto;
  padding: 50px 20px 30px 30px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  line-height: 1.8;
  white-space: pre-line;
}

.container p {
  margin-bottom: 20px;

  font-size: 16px;
}




















































































.BlurBackgroundha {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  filter: blur(10px); /* Blurs the background */
  z-index: 0; /* Keep it behind other content */
}

.GlassOverlayha {
  position: relative;
  width: 100%; /* Adjust size as needed */
  padding: 20px;
 
  border-radius: 1px; /* Add smooth corners */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  backdrop-filter: blur(5px); /* Glass-like blur effect */
  
  color: #000;
  z-index: 1; /* Keeps content above the blurred background */
  margin: 0px auto; /* Centers the overlay horizontally */
  text-align: center;
}

















.containeram {
  text-align: start;

    padding:  40px;
    background-color: #fefefe;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    line-height: 1.8;
    white-space: pre-line;
  }
  























  .bgss{
    font-size: 30px !important;
    background-color: #f3f3f3 !important;
    text-align: center !important;
  }

  .Footerm {
    background-color: rgba(183, 183, 183, 0); /* Semi-transparent red */
    backdrop-filter: blur(0px); /* Blurs the background behind the footer */
    
   
    padding: 10px;
  }


  .Footer {
    background-color: rgba(183, 183, 183, 0); /* Semi-transparent red */
    backdrop-filter: blur(0px); /* Blurs the background behind the footer */
    
   
    padding: 50px;
  }
  
.footerzz{
  background-color: #1d6ac0;
}


  .banner_main{

    font-family: 'YourCustomFont' !important;

    height: 100vh;
   background-color: #000;
    background-repeat: no-repeat!important;
    background-size: cover !important;
  
    
  }

  .wood_divider {
    height: 141px;
    background-color: #000000dd !important;
    
    /* Add border only to the top side */
    border-top: 5px solid #000;
    border-bottom:  5px solid #000;;
  }
  

  .wood_dividermo {
 
    background-color: #000000dd !important;
    
    /* Add border only to the top side */
 
  }
  




































  
























































  

.HomepageAbouth {

 


  

}



.HomepageAbouths {
background-color: #1d6ac0;
  background-size: cover; /* Ensures the image covers the whole container */
/* Makes the parallax effect smoother */
  background-position: center  center  ;
  background-repeat: no-repeat;

  width: 100%;
}

.HomepageAbouthsf{
  background-color: #694eb8;
  background-size: cover; /* Ensures the image covers the whole container */
/* Makes the parallax effect smoother */
  background-position: center  center  ;

  background-repeat: no-repeat;
height: 100%;
  width: 100%;
}














.centeritallstart{
  display: flex;
  justify-content: start;
  text-align: start;
  align-items: start;
}











.centeritall{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}











/* CSS */
.button-82-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;

  
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  
  -webkit-background-clip: text;

  text-shadow: 2px 2px 8px rgb(255, 0, 0),
               4px 4px 12px rgb(255, 255, 255);
  letter-spacing: 3px; /* Add this to increase space between letters */

  background: linear-gradient(45deg, #ffffff, #ffffff, rgb(241, 241, 241), #ffffff, #ffffff);
}

.button-82-front {
  display: block;
  position: relative;
  font-size: 1.1rem;
  background: linear-gradient(45deg, #ffffff, #ffffff, rgb(241, 241, 241), #ffffff, #ffffff);
  background-clip: text;
  
  -webkit-background-clip: text;

  text-shadow: 2px 2px 8px rgb(126, 0, 0),
               4px 4px 12px rgb(255, 255, 255);
  letter-spacing: 3px; /* Add this to increase space between letters */

  will-change: transform;
  transform: translateY(-4px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}










































.catextm{

  text-align: center;
  align-items: center;
  font-size: 13px;
  color: #fff;
}







.address-box {
  background-color: rgba(46, 46, 46, 0.951) !important; /* Semi-transparent background *//* Slightly darker purple */
  padding: 10px;
  box-shadow: inset -3px -3px 0 #252525;
  border-radius: 20px;

border: 2px solid #000;

  font-size: 1.3rem;
  color: white;
width:100%;
  text-align: center;
}

.address-boxm {
  background-color: rgba(46, 46, 46, 0.951) !important; /* Semi-transparent background */ /* Slightly darker purple */
  padding: 10px;
  box-shadow: inset -3px -3px 0 #252525;
  border-radius: 20px;
border: 2px solid #000;
color: #ffffff;
font-size: 12px;
  font-family: "YourCustomFontCA", system-ui !important;

}

.copy-btn {
  background-color: white;
  color: black;
  
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: "YourCustomFontCA", system-ui !important;

}

.copy-btn .icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.copy-btn:hover {
  background-color: #e0e0e0;
}





.shima_headfs {
  position: relative;
  top: 20px;
  font-family: 'YourCustomFonts';
 color: #ffbf47 !important;
 text-shadow: #000 0.2px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 1px 0;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(45deg, #dddddd, #ffffff, rgb(241, 241, 241), #ffffff, #002338);
  background-clip: text;
  position: relative;
text-decoration: none !important;
  -webkit-background-clip: text;
  color: transparent;


  letter-spacing: 2px; /* Add this to increase space between letters */
}







.shima_headf {
  text-align: center;
  font-size: 33px;
  font-weight: bold;
  background: linear-gradient(45deg, #ffffff, #ffffff, rgb(241, 241, 241), #ffffff, #ffffff);
  background-clip: text;
  position: relative;
  top: 9px;
  -webkit-background-clip: text;
  color: transparent;


  letter-spacing: 2px; /* Add this to increase space between letters */
}


.backofitfeelglassy {
  background-color: rgba(231, 0, 0, 0.858); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Creates the frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
border-top:3px solid #000 ;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  padding: 20px; /* Optional: Adds padding inside the element */
}


.disclaimer{
  color: #ffffff;
  font-size: 24px;
}




.backofitfeelglassym {
  background-color: rgba(255, 43, 156, 0.858); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Creates the frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
border-top:3px solid #000 ;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  padding: 7px; /* Optional: Adds padding inside the element */
}

.about_box{
 
  border-radius: 60px;


}

.about_boxm{
  background-color:  #ffffffe7; /* Semi-transparent background */
  border-radius: 20px;

border: 3px solid #000;
  box-shadow: inset -12px -12px 0 #252525;
}

.backoffly {
  background-color: #000 !important;
  border-radius: 40px;
  border: 2px solid #555;
  box-shadow: inset -16px -18px 0 #000000;
  transition: all 0.3s ease-in-out;
}
































































/* From Uiverse.io by adamgiebl */ 
.buttonfs {
  font-size: 22px;
  font-weight: 600;
  
  padding: 0.6em 1.8em;
  border: 1px solid #000;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
 background-color: #475c72;
  color: #fff;
  box-shadow: inset 0 0 10px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
}

/* Text color change and pop-up effect */
.buttonfs:hover {
  color: #000000;
  background-color: #ffffff !important;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6), 0 0 9px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(-0.4em); /* Adds the pop-up effect */
}

/* Animated gradient effect */
.buttonfs:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform .4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%, rgba(27, 253, 156, 0.1) 60%, transparent 100%);
}

/* Gradient animation on hover */
.buttonfs:hover:before {
  transform: translateX(15em);
}



.banner_mainf{

  font-family: 'YourCustomFont' !important;
  border-bottom: 3px solid #000;
border-top: 3px solid #000;
height: 100%;
 background-color: #ffbf47;
  background-repeat: no-repeat!important;
  background-size: cover !important;

  
}


.foot_under{
  padding: 25px;
  position: relative;
 left: 100px;
 background-color:#ffbf47;
}


.footlogo{
  width: 200px;


}
.footlogop{
  width: 65px;
  height: 65px;
  border: 1px solid #000000;
  border-radius: 40px;
  position: relative;
  top: 5px;
}

.holdnav{
  padding: 5px;
}














.shima_headfzx {
  text-align: center;
  font-size: 30px;

color: #ff9865 !important;

  letter-spacing: 0px; /* Add this to increase space between letters */
}






body {
  background-color:#ffbf47; /* Set background color to black */
}

.shimano {
  display: flex; /* Ensure flex properties work */
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  font-weight: 900;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
  letter-spacing: 3px;
  font-size: 80px;
}






.about_boxshim {
  background-color:  #ffffffe7; /* Semi-transparent background */
  border-radius: 30px;
  border: 3px solid #000;
  width: 100%;
  height: 140px;
  box-shadow: inset -10px -10px 0 #252525;
  transition: all 0.4s ease; /* Smooth transition for hover effect */
}

.about_boxshim:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.8), inset -10px -10px 0 #000000;
  background-color: rgba(66, 66, 66, 0.95); /* Change background color on hover */
}



.shimunder {
  text-align: center;
  color: #ffffff;

}
























.mute-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;

  z-index: 1000; /* Ensure it appears above other content */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.mute-btn:hover {
  background-color: #333;
  transform: scale(1.05);
}


.move_to_left{
  position: relative;
  top: 10px;
  left: 170px;
}


.move_to_right{
  position: relative;
  top: 10px;
  left: 35px;
}



.desktop_CA{
  position: relative;
  left: 100px;
}



































/* From Uiverse.io by adamgiebl */ 
.buttonfsc {
  font-size: 20px;
  font-weight: 600;
  font-family: 'YourCustomFont' !important;
  padding: 0.4em 1em;
  border: 1px solid #000;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  background-color: #475c72;
  color: #fff;
  box-shadow: inset 0 0 10px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
}

/* Text color change and pop-up effect */
.buttonfsc:hover {
  color: #000000;
  background-color: #ffffff !important;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6), 0 0 9px 3px rgba(0, 0, 0, 0.2);
 
}

/* Animated gradient effect */
.buttonfsc:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform .4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%, rgba(27, 253, 156, 0.1) 60%, transparent 100%);
}

/* Gradient animation on hover */
.buttonfsc:hover:before {
  transform: translateX(15em);
}


.caAvailableOn{
  color: #fff;
  font-size: 28px !important;
  position: relative;
  top: 15px;
}









































.buy__arrow {


width: 125px;
height: 80px;
background-color: #c61010;
color: #fff;
box-shadow: inset 0 0 13px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
border: 1px solid #000;
 position: relative;
top: 80px;
  color: rgb(110, 139, 193);
right: 40px;

  border-image: initial;
  border-radius: 32px;
}




.buy__arrowb {

z-index: 10;
  width: 125px;
  height: 80px;
  background-color: #c61010;
  color: #fff;
  box-shadow: inset 0 0 13px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #000;
   position: relative;
  top: 80px;
    color: rgb(110, 139, 193);
  right:  40px;
 
    border-image: initial;
    border-radius: 32px;
  }
  
  



.how-to-buy-boxa{
  background-color:  #ffffffe7 !important; /* Semi-transparent background */
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  border-radius: 35px;
  border-image: initial;
height: 400px;
 
  padding: 32px;


}
.how-to-buy-boxab{
  background-color:  #ffffffe7 !important; /* Semi-transparent background */
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  border-radius: 35px;
  border-image: initial;
  border-radius: 42px;
  padding: 32px;
  height: 100%;
 

  z-index: -1;

}


.how-to-buy-box {
  background-color:  #ffffffe7 !important; /* Semi-transparent background */
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  border-radius: 35px;
  height: 100%;
 
  border-image: initial;
  z-index: -1;
  border-radius: 42px;
  padding: 32px;
width: 100%;
  position: relative; /* Or absolute/fixed depending on your layout */
}


.arrow{
  text-align: center;
  justify-content: center;
  padding: 3px;
  font-size: 40px;
  color: #fff;

  position: relative;
  top: 3px;
}












.border_socials{
  font-weight: 900;
  color: #fccf00;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
  letter-spacing: 3px;
}













































.shimanopa {
  display: flex; /* Ensure flex properties work */
  text-align: center;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  font-weight: 100;
  text-shadow: #000 2px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
  letter-spacing: 3px;

}












































.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure it's behind other content */
}

.home-container {
  position: relative; /* Ensure positioning is handled correctly */
}





.fontzm{
  font-family: 'YourCustomFont' !important;
  font-size: 22px !important;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 1px 0;
}




.fontzmt{
  font-family: 'YourCustomFont' !important;
  font-size: 19px !important;
  
}













.questionnumber{
  font-family: 'YourCustomFontNumber';
}










.mistVideo {
  position: fixed; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
  object-fit: cover; /* Ensure the video covers the entire area */
  z-index: 100; /* Behind other content */
  opacity: 1; /* Start fully visible */
  transition: opacity 2s; /* For the smooth transition */
}

.content {
  position: relative; /* Positioning context for the content */
  z-index: 1; /* Bring content above the background */
}



















.modal_effect{
  font-size: 18px;
  font-weight: 100 !important;
  padding: 7px;
}

@media screen and (min-width: 1000px){
  .modal_effect{
    font-size: 25px;
  }
  
  
}




.centeritallnow{
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}























































.shimanoms {

  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff; /* Text color */
  font-weight: 900;
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;

  font-size: 50px;
}













.how-to-buy-boxams{
  background-color:  #ffffffe7 !important; /* Semi-transparent background */
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  font-family: 'YourCustomFonts';
  border-radius: 35px;
  border-image: initial;
height: 100%;
 
  padding: px;


}


















.buy__arrowms {



background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 13px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #000;
   position: relative;
  justify-content: center;
  align-items: center;
width: 140px;
 
  
    border-image: initial;
    border-radius: 25px;
  }
  





























  















  


.about_boxshimm {
  background-color:  #ffffffe7; /* Semi-transparent background */
  border-radius: 30px;
  border: 3px solid #000;
  width: 75%;
  height: 130px;
  box-shadow: inset -10px -10px 0 #252525;
  transition: all 0.4s ease; /* Smooth transition for hover effect */
}

.about_boxshimm:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.8), inset -10px -10px 0 #000000;
  background-color: rgba(66, 66, 66, 0.95); /* Change background color on hover */
}



.about-button{}


















/* Make sure the parent container has relative positioning */
.parent-container {
  position: relative;
  height: 75vh; /* Example height of the parent container */
}

.movetoend {
  position: absolute;
  bottom: 0; /* Move the div to the bottom of its parent container */
  width: 100%; /* Make it span across the full width */
  display: flex; /* Center the content horizontally */
  justify-content: center;
  padding: 20px; /* Optional padding */
}

.centeritall {
  display: flex;
  justify-content: center;
  align-items: center;
}





































/* From Uiverse.io by adamgiebl */ 
.buttonfscc {
  font-size: 20px;
  font-weight: 600;
  font-family: 'YourCustomFont' !important;
  padding: 0.4em 1em;
  border: 1px solid #000;
  height: 53px;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  background-color: #475c72;
  color: #fff;
  box-shadow: inset 0 0 10px rgb(0, 0, 0), 0 0 9px 3px rgba(0, 0, 0, 0.1);
}

/* Text color change and pop-up effect */
.buttonfscc:hover {
  color: #000000;
  background-color: #ffffff !important;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6), 0 0 9px 3px rgba(0, 0, 0, 0.2);
 
}

/* Animated gradient effect */
.buttonfscc:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform .4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%, rgba(27, 253, 156, 0.1) 60%, transparent 100%);
}

/* Gradient animation on hover */
.buttonfscc:hover:before {
  transform: translateX(15em);
}












.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; /* Remove the border */
}









































.your-section {
  position: relative; /* Ensures .waves is positioned relative to this section */
}

.waves {
  position: absolute; /* Positioning relative to the parent section */
  bottom: 0;          /* Aligns the element to the bottom of the section */
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /* Fix for Safari gap */
  min-height: 100px;
  max-height: 150px;
}


/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}


































img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}






.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent; /* Ensure no black background is visible */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: hidden;
}

.mistVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 2s ease; /* Smooth fade-out */
}

.content {
  opacity: 0; /* Initially hidden */
  transition: opacity 2s ease; /* Smooth fade-in */
}



























































































































.flip-card {
  background-color: none !important;
  border-radius: 35px;

  height: 320px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  border-radius: 35px;
  height: 100%;
  text-align: center;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-inner.flipped {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #ffffffe7 !important; /* Semi-transparent background */
  box-shadow: inset -7px -7px 0 #252525;
  border: 3px solid #000000;
  border-radius: 30px;
  padding: 32px;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  font-family: 'YourCustomFonts' !important;
  color: white;
  transform: rotateY(180deg);
}



































































.h1home{
  font-size: 27px;
  color: #fff;
  text-align: center;
  font-family: 'YourCustomFonts';
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
}




.g1{
  font-size: 18px;
  color: #FFF;
text-align: center;
  z-index: 2;
  position: relative;
  font-family: 'YourCustomFonts';
}


.l1{
  font-size: 18px;
  color: #FFF;
text-align: center;
  z-index: 2;
  position: relative;
  font-family: 'YourCustomFonts';
}




.mobs{
 overflow-x: hidden !important;
}
























.h1homem{
  font-size: 20px;
  color: #fff;
  text-align: center;
  font-family: 'YourCustomFonts';
  text-shadow: #000 4px 0 0,
               #000 3.87565px .989616px 0,
               #000 3.51033px 1.9177px 0,
               #000 2.92676px 2.72656px 0,
               #000 2.16121px 3.36588px 0,
               #000 1.26129px 3.79594px 0,
               #000 .282949px 3.98998px 0,
               #000 -.712984px 3.93594px 0,
               #000 -1.66459px 3.63719px 0,
               #000 -2.51269px 3.11229px 0,
               #000 -3.20457px 2.39389px 0,
               #000 -3.69721px 1.52664px 0,
               #000 -3.95997px .56448px 0,
               #000 -3.97652px -.432781px 0,
               #000 -3.74583px -1.40313px 0,
               #000 -3.28224px -2.28625px 0,
               #000 -2.61457px -3.02721px 0,
               #000 -1.78435px -3.57996px 0,
               #000 -.843183px -3.91012px 0,
               #000 .150409px -3.99717px 0,
               #000 1.13465px -3.8357px 0,
               #000 2.04834px -3.43574px 0,
               #000 2.83468px -2.82216px 0,
               #000 3.44477px -2.03312px 0,
               #000 3.84068px -1.11766px 0,
               #000 3.9978px -.132717px 0,
               #000 0 4px 0;
}


