
.buttonz {
    --bevel: 3px;
    --border-width: 3px;
    background-color: #1d6ac0;
  font-size: 30px;
    font-weight: 600;
    color: #1d2119;
  
    min-width: 10em;
  }
  
  .button-outline {
    --bevel-1: calc((var(--bevel) + (var(--border-width)) * 2) - ((var(--border-width) * #{tan(22.5deg)})) * 2);
    --bevel-2: calc(var(--bevel-1) + var(--border-width));
    --bevel-3: calc(var(--bevel-2) + var(--border-width));
    display: block;
    margin-top: calc(var(--border-width) * -1);
    margin-left: calc(var(--border-width) * -1);
    padding: var(--border-width);
    background-color:#000000;
    clip-path: polygon(
      var(--bevel-2) var(--border-width),
      calc(100% - var(--bevel-2)) var(--border-width),
      100% var(--bevel-3),
      100% calc(100% - var(--bevel-1)),
      calc(100% - var(--bevel-1)) 100%,
      var(--bevel-3) 100%,
      var(--border-width) calc(100% - var(--bevel-2)),
      var(--border-width) var(--bevel-2)
    );
    transition-property: clip-path;
    transition-duration: .2s;
    
    .buttonz:hover:not(:active) &,
    .buttonz:focus-visible:not(:active) & {
      clip-path: polygon(
        var(--bevel-1) 0,
        calc(100% - var(--bevel-3)) 0,
        100% var(--bevel-3),
        100% calc(100% - var(--bevel-1)),
        calc(100% - var(--bevel-1)) 100%,
        var(--bevel-3) 100%,
        0 calc(100% - var(--bevel-3)),
        0 var(--bevel-1)
      );
    }
  }
  
  .button-shadow {
    --padding: calc(var(--border-width) * 2);
    --bevel-1: calc((var(--bevel) + var(--border-width)) - (var(--border-width) * #{tan(22.5deg)}));
    --bevel-2: calc(var(--bevel-1) + var(--border-width));
    --bevel-3: calc(var(--bevel-2) + var(--border-width));
    display: block;
    padding: calc(var(--border-width) * 2) var(--padding) var(--padding) calc(var(--border-width) * 2) ;
    background-color: #1d2119;
    clip-path: polygon(
      var(--bevel-2) var(--border-width),
      calc(100% - var(--bevel-2)) var(--border-width),
      100% var(--bevel-3),
      100% calc(100% - var(--bevel-1)),
      calc(100% - var(--bevel-1)) 100%,
      var(--bevel-3) 100%,
      var(--border-width) calc(100% - var(--bevel-2)),
      var(--border-width) var(--bevel-2)
    );
    transition-property: clip-path;
    transition-duration: .2s;
    
    .buttonz:hover:not(:active) &,
    .buttonz:focus-visible:not(:active) & {
      clip-path: polygon(
        var(--bevel-1) 0,
        calc(100% - var(--bevel-3)) 0,
        100% var(--bevel-3),
        100% calc(100% - var(--bevel-1)),
        calc(100% - var(--bevel-1)) 100%,
        var(--bevel-3) 100%,
        0 calc(100% - var(--bevel-3)),
        0 var(--bevel-1)
      );
    }
  }
  
  .button-inside {
    --padding-vertical: 6px;
    display: block;
    padding: var(--padding-vertical) 24px calc(var(--padding-vertical) - .125em);
    background-color: #fff;
    clip-path: polygon(
      var(--bevel) 0,
      calc(100% - var(--bevel)) 0,
      100% var(--bevel),
      100% calc(100% - var(--bevel)),
      calc(100% - var(--bevel)) 100%,
      var(--bevel) 100%,
      0 calc(100% - var(--bevel)),
      0 var(--bevel)
    );
    text-align: center;
    transition-property: transform;
    transition-duration: .2s;
    
    .buttonz:hover:not(:active) &,
    .buttonz:focus-visible:not(:active) & {
      transform: translate(calc(var(--border-width) * -1), calc(var(--border-width) * -1));
    }
    
    .buttonz:hover &,
    .buttonz:focus-visible & {
      background-color: #fcd200;
      background-image: 
        linear-gradient(to right, transparent, rgba(252, 210, 0, .9)),
        radial-gradient(#fff60d 1px, transparent 0),
        radial-gradient(#fff60d 1px, transparent 0);
      background-size: auto, 6px 6px, 6px 6px;
      background-position: 0 0, 0 0, 3px 3px;
      animation: scroll-background 1s linear infinite;
    }
  }
  
  @keyframes scroll-background {
    to {
      background-position-x: 0, -6px, -3px;
    }
  }
  
  .button-text-characters-container {
    display: inline-block;
    transform: skewX(-6deg);
  }
  
  .button-text-character {
    display: inline-block;
    
    .buttonz:hover:not(:active) &,
    .buttonz:focus-visible:not(:active) & {
      animation: jump 4s cubic-bezier(.75, .25, 1, 2) var(--delay) infinite;
    }
  }
  
  @keyframes jump {
    5% {
      transform: translateY(-.125em);
    }
    10% {
      transform: translateY(0);   
    }
  }